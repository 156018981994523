import { Link } from "react-router-dom";
import styled from "styled-components";
import { Highlight } from "./Highlight";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
    
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
    }
`

export const HomeHighlights = () => {
    return (
        <Wrapper>
            <Highlight title="Internships" data="4" />
            <Highlight title="CodeChef" data={`5⭐`} />
            <Highlight title="Projects" data="5+" />
            <Highlight title="Hackathons Won" data="3+" />
        </Wrapper>
    );
}
